import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { categorizeFiles, createBulkChapters, bulkUploadPages } from '../../api';
import Alert from '../utils/Alert';  

const PatternManagement = ({ extractedFiles, selectedMangaId }) => {
  const [patterns, setPatterns] = useState([]);
  const [newPattern, setNewPattern] = useState({ regex: '', name: '' });
  const [categorizedFiles, setCategorizedFiles] = useState({});
  const [isCategorizing, setIsCategorizing] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [alert, setAlert] = useState(null);  
  const [patternCounts, setPatternCounts] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [suggestedPatterns, setSuggestedPatterns] = useState([]);
  const [createdChapters, setCreatedChapters] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    setTotalPages(extractedFiles.length);
    const uniquePatterns = new Set();
    extractedFiles.forEach(file => {
      const match = file.name.match(/c(\d+)/i);
      if (match) {
        uniquePatterns.add(match[0]);
      }
    });
    setSuggestedPatterns(Array.from(uniquePatterns));
  }, [extractedFiles]);

  useEffect(() => {
    const updatePatternCounts = async () => {
      const counts = {};
      for (const pattern of patterns) {
        let modifiedRegex = pattern.regex;
        if (!modifiedRegex.includes('(?<chapter>')) {
          modifiedRegex = modifiedRegex.replace(/(\d+)/, '(?<chapter>$1)');
        }
        if (!modifiedRegex.includes('(?<page>')) {
          modifiedRegex += '.*p(?<page>\\d+)';
        }

        try {
          const response = await categorizeFiles(selectedMangaId, { 
            files: extractedFiles, 
            pattern: modifiedRegex
          });
          counts[pattern.name] = Object.values(response.data.categorizedFiles)
            .reduce((acc, chapter) => acc + chapter.length, 0);
        } catch (err) {
          console.error('Error updating pattern count:', err.message);
          showAlert(`Error updating pattern count: ${err.message}`, 'error');
        }
      }
      setPatternCounts(counts);
    };

    updatePatternCounts();
  }, [patterns, extractedFiles, selectedMangaId]);

  const showAlert = (message, type) => {
    setAlert({ message, type });
  };

  const handleNewPatternChange = (e) => {
    setNewPattern({ ...newPattern, [e.target.name]: e.target.value });
  };

  const addPattern = () => {
    if (newPattern.regex && newPattern.name) {
      setPatterns([...patterns, newPattern]);
      setNewPattern({ regex: '', name: '' });
      showAlert('Pattern added successfully', 'success');
    } else {
      showAlert('Please enter both regex and name for the pattern', 'error');
    }
  };

  const handleCategorize = async () => {
    if (patterns.length === 0) {
      showAlert('Please add at least one pattern', 'error');
      return;
    }

    setIsCategorizing(true);
    setAlert(null);

    try {
      const categorizedResults = {};
      const counts = {};
      for (const pattern of patterns) {
        let modifiedRegex = pattern.regex;
        if (!modifiedRegex.includes('(?<chapter>')) {
          modifiedRegex = modifiedRegex.replace(/(\d+)/, '(?<chapter>$1)');
        }
        if (!modifiedRegex.includes('(?<page>')) {
          modifiedRegex += '.*p(?<page>\\d+)';
        }

        const response = await categorizeFiles(selectedMangaId, { 
          files: extractedFiles, 
          pattern: modifiedRegex
        });
        categorizedResults[pattern.name] = response.data.categorizedFiles;
        counts[pattern.name] = Object.values(response.data.categorizedFiles)
          .reduce((acc, chapter) => acc + chapter.length, 0);
      }
      setCategorizedFiles(categorizedResults);
      setPatternCounts(counts);
      showAlert('Files categorized successfully', 'success');
    } catch (err) {
      showAlert(`Error categorizing files: ${err.message}`, 'error');
    } finally {
      setIsCategorizing(false);
    }
  };

  const handleCreateChapters = async () => {
    try {
      const chaptersToCreate = Object.entries(categorizedFiles).map(([patternName, chapters]) => ({
        title: patternName
      }));

      const response = await createBulkChapters(selectedMangaId, chaptersToCreate);
      setCreatedChapters(response.data.results.createdChapters);
      showAlert('Chapters created successfully', 'success');
    } catch (err) {
      showAlert(`Error creating chapters: ${err.response?.data?.error || err.message}`, 'error');
    }
  };

  const handleConfirmUpload = async () => {
    if (createdChapters.length === 0) {
      showAlert('Please create chapters before moving pages', 'error');
      return;
    }
  
    setIsConfirming(true);
    setAlert(null);
  
    try {
      for (const chapter of createdChapters) {
        const pages = categorizedFiles[chapter.title];
        if (!pages || Object.keys(pages).length === 0) {
          console.warn(`No pages found for chapter: ${chapter.title}`);
          continue;
        }
  
        const pagesData = Object.values(pages).flat().map(page => ({
          filename: page.filename,
          tempPath: page.path,
          pageNumber: page.pageNumber
        }));
  
        try {
          await bulkUploadPages(selectedMangaId, chapter.id, { pages: pagesData });
        } catch (err) {
          showAlert(`Error uploading pages for ${chapter.title}: ${err.message}`, 'error');
        }
      }
  
      setTimeout(() => {
        navigate(`/manga/${selectedMangaId}`);
      }, 2000); // Wait for 2 seconds before navigating
    } catch (err) {
      showAlert(`Error moving pages: ${err.message}`, 'error');
    } finally {
      setIsConfirming(false);
    }
  };

  const handleSuggestedPatternClick = (pattern) => {
    setNewPattern({ ...newPattern, regex: pattern });
  };

  return (
    <div className="mt-4 rounded-md bg-gray-800 p-3 text-white">
      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
      <h3 className="text-lg font-bold">Extracted Files</h3>
      <ul className="list-disc pl-5 max-h-40 overflow-y-auto">
        {extractedFiles.map((file, index) => (
          <li key={index}>{file.name}</li>
        ))}
      </ul>
      
      <div className="mt-4">
        <h4 className="font-bold">Suggested Patterns</h4>
        <div className="flex flex-wrap gap-2 mt-2">
          {suggestedPatterns.map((pattern, index) => (
            <button
              key={index}
              onClick={() => handleSuggestedPatternClick(pattern)}
              className="px-2 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              {pattern}
            </button>
          ))}
        </div>
      </div>
      
      <div className="mt-4">
        <h4 className="font-bold">Add Pattern (Total Pages: {totalPages})</h4>
        <input
          type="text"
          name="regex"
          placeholder="Enter pattern (e.g., c002)"
          value={newPattern.regex}
          onChange={handleNewPatternChange}
          className="w-full px-3 py-2 border rounded-md mb-2 bg-gray-700 text-white"
        />
        <input
          type="text"
          name="name"
          placeholder="Enter pattern name (e.g., Chapter 2)"
          value={newPattern.name}
          onChange={handleNewPatternChange}
          className="w-full px-3 py-2 border rounded-md mb-2 bg-gray-700 text-white"
        />
        <button 
          onClick={addPattern}
          className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Add Pattern
        </button>
      </div>
      
      <div className="mt-4">
        <h4 className="font-bold">Current Patterns</h4>
        <ul className="list-disc pl-5">
          {patterns.map((pattern, index) => (
            <li key={index}>{pattern.name}: {pattern.regex} (Matched Pages: {patternCounts[pattern.name] || 0})</li>
          ))}
        </ul>
      </div>
      
      <button 
        onClick={handleCategorize}
        disabled={isCategorizing || patterns.length === 0}
        className="mt-4 px-4 py-2 bg-purple-500 text-white rounded-md disabled:bg-gray-400 hover:bg-purple-600"
      >
        {isCategorizing ? 'Categorizing...' : 'Categorize Files'}
      </button>

      {Object.keys(categorizedFiles).length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-bold">Categorized Files</h3>
          <div className="max-h-60 overflow-y-auto">
            {Object.entries(categorizedFiles).map(([patternName, chapters]) => (
              <div key={patternName} className="mt-2">
                <h4 className="font-bold">{patternName}</h4>
                {Object.entries(chapters).map(([chapter, pages]) => (
                  <div key={chapter} className="ml-4">
                    <h5 className="font-semibold">Chapter {chapter}</h5>
                    <ul className="list-disc pl-5">
                      {pages.map((page, idx) => (
                        <li key={idx}>{page.filename} (Page {page.pageNumber})</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
          </div>
          
          <button 
            onClick={handleCreateChapters}
            disabled={isCategorizing || createdChapters.length > 0}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md disabled:bg-gray-400 hover:bg-green-600"
          >
            {isCategorizing ? 'Creating Chapters...' : 'Create Chapters'}
          </button>

          {createdChapters.length > 0 && (
            <button 
              onClick={handleConfirmUpload}
              disabled={isConfirming}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400 hover:bg-blue-600"
            >
              {isConfirming ? 'Uploading Pages...' : 'Upload Pages'}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default PatternManagement;
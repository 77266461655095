import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createManga, updateMangaCoverImage } from '../api';
import { AlertCircle } from 'lucide-react';

function MangaForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    if (!title.trim()) {
      setError('Title is required');
      setIsSubmitting(false);
      return;
    }

    try {
      // Create manga without cover image first
      const mangaData = {
        title: title.trim(),
        description: description.trim()
      };

      const response = await createManga(mangaData);
      const newMangaId = response.data.id;

      // If there's a cover image, upload it separately
      if (coverImage) {
        const formData = new FormData();
        formData.append('coverImage', coverImage);
        await updateMangaCoverImage(newMangaId, formData);
      }

      navigate('/');
    } catch (error) {
      console.error('Error creating manga:', error);
      setError(error.response?.data?.error || 'An error occurred while creating the manga. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCoverImageChange = (e) => {
    if (e.target.files[0]) {
      setCoverImage(e.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded shadow-md text-white max-w-2xl mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-6 text-yellow-500">Add New Manga</h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-500 text-white rounded flex items-center">
          <AlertCircle size={20} className="mr-2" />
          <span>{error}</span>
        </div>
      )}

      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-400 font-bold mb-2">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full px-3 py-2 border rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-400 font-bold mb-2">Description:</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full px-3 py-2 border rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
          rows="4"
        />
      </div>

      <div className="mb-6">
        <label htmlFor="coverImage" className="block text-gray-400 font-bold mb-2">Cover Image:</label>
        <input
          type="file"
          id="coverImage"
          onChange={handleCoverImageChange}
          className="w-full px-3 py-2 border rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
          accept="image/*"
        />
      </div>

      <button 
        type="submit" 
        className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-400 transition-colors duration-300 w-full"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Adding Manga...' : 'Add Manga'}
      </button>
    </form>
  );
}

export default MangaForm;
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { getMangaList, renameManga, deleteManga } from '../api';
import MangaCardSmall from './utils/MangaCardSmall';
import EditModal from './utils/EditModal';
import { Search } from 'lucide-react';

const ITEMS_PER_PAGE = 12;

function MangaList() {
  const [mangas, setMangas] = useState([]);
  const [editingManga, setEditingManga] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const observer = useRef();

  const lastMangaElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  const fetchMangas = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getMangaList();
      setMangas(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching manga list:', error);
      setError('Failed to load manga list. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMangas();
  }, [fetchMangas]);

  const filteredMangas = useMemo(() => 
    mangas.filter(manga => 
      manga.title.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [mangas, searchTerm]
  );

  const displayedMangas = useMemo(() => 
    filteredMangas.slice(0, page * ITEMS_PER_PAGE),
    [filteredMangas, page]
  );

  useEffect(() => {
    setHasMore(filteredMangas.length > page * ITEMS_PER_PAGE);
  }, [filteredMangas, page]);

  const handleRename = useCallback(async (newTitle) => {
    if (!editingManga) return;
    try {
      await renameManga(editingManga.id, newTitle);
      setMangas(prevMangas => prevMangas.map(manga => 
        manga.id === editingManga.id ? { ...manga, title: newTitle } : manga
      ));
      setEditingManga(null);
    } catch (error) {
      console.error('Error renaming manga:', error);
    }
  }, [editingManga]);

  const handleDelete = useCallback(async (mangaId) => {
    try {
      await deleteManga(mangaId);
      setMangas(prevMangas => prevMangas.filter(manga => manga.id !== mangaId));
    } catch (error) {
      console.error('Error deleting manga:', error);
    }
  }, []);

  const handleEdit = useCallback((manga) => {
    setEditingManga(manga);
  }, []);

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  }, []);

  if (error) {
    return <div className="text-red-500 text-center py-8">{error}</div>;
  }

  return (
    <div className="manga-list p-6 bg-gray-900 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-yellow-500">Your Manga Collection</h1>
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search manga..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full px-4 py-2 bg-gray-800 text-white rounded-md pr-10"
        />
        <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:grid-cols-2">
        {displayedMangas.map((manga, index) => (
          <div 
            key={manga.id} 
            ref={index === displayedMangas.length - 1 ? lastMangaElementRef : null}
          >
            <MangaCardSmall
              manga={manga}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </div>
        ))}
      </div>
      {isLoading && <div className="text-white text-center py-8">Loading...</div>}
      <EditModal
        isOpen={editingManga !== null}
        onClose={() => setEditingManga(null)}
        onSave={handleRename}
        initialValue={editingManga?.title || ''}
        title="Edit Manga Title"
        inputType="text"
      />
    </div>
  );
}

export default React.memo(MangaList);
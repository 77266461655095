// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import Sidebar from './components/Sidebar';
import ChapterForm from './components/ChapterForm';
import ZipUploader from './components/ZipUploader';
import ChapterList from './components/ChapterList';
import ChapterDetail from './components/ChapterDetail';
import MangaForm from './components/MangaForm';
import MangaList from './components/MangaList';

function AuthenticatedApp() {
  return (
    <div className="flex h-screen bg-slate-900 ">
      <Sidebar />
      <div className="flex-1 overflow-auto">
        <div className="container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<MangaList />} />
            <Route path="/manga/new" element={<MangaForm />} />
            <Route path="/manga/:mangaId" element={<ChapterList />} />
            <Route path="/chapter/new" element={<ChapterForm />} />
            <Route path="/manga/:mangaId/chapter/:chapterId" element={<ChapterDetail />} />
            <Route path="/upload-zip" element={<ZipUploader />} />
            <Route path="/chapter/new/:mangaId" element={<ChapterForm />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function UnauthenticatedApp() {
  return (
    // <div className="flex justify-center items-center min-h-screen bg-slate-800">
    //   <div className="w-full max-w-md">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    //   </div>
    // </div>
  );
}

function AppContent() {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <Router>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
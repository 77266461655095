import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getChapterList, getMangaDetails, updateChapter, deleteChapter } from '../api';
import { Plus, AlertCircle, ChevronLeft, ChevronRight } from 'lucide-react';
import MangaCard from './utils/MangaCard';
import ChapterSlugEditor from './utils/ChapterSlugEditor';
import SearchBar from './utils/SearchBar';
import Alert from './utils/Alert';

function ChapterList() {
  const [chapters, setChapters] = useState([]);
  const [filteredChapters, setFilteredChapters] = useState([]);
  const [manga, setManga] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [alert, setAlert] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { mangaId } = useParams();

  const chaptersPerPage = 60;
  const columnsPerPage = 2;
  const rowsPerColumn = 20;

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [chaptersResponse, mangaResponse] = await Promise.all([
        getChapterList(mangaId),
        getMangaDetails(mangaId)
      ]);
  
      const sortedChapters = chaptersResponse.data.sort((a, b) => a.title.localeCompare(b.title));
      setChapters(sortedChapters);
      setFilteredChapters(sortedChapters);
      setManga(mangaResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load manga details and chapters. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [mangaId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const filtered = chapters.filter(chapter =>
      (chapter.title && chapter.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (chapter.slug && chapter.slug.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredChapters(filtered);
    setCurrentPage(1);
  }, [searchTerm, chapters]);

  const handleMangaUpdate = useCallback(async () => {
    await fetchData();
  }, [fetchData]);

  const handleChapterUpdate = async (chapterId, updateData) => {
    console.log('Updating chapter:', chapterId, updateData);  // Debug log
    try {
      const updatedChapter = await updateChapter(chapterId, updateData);
      console.log('API response:', updatedChapter);  // Debug log
      
      setChapters(prevChapters => 
        prevChapters.map(chapter => 
          chapter.id === chapterId ? { ...chapter, ...updateData } : chapter
        )
      );
      
      setFilteredChapters(prevFiltered => 
        prevFiltered.map(chapter => 
          chapter.id === chapterId ? { ...chapter, ...updateData } : chapter
        )
      );
  
      console.log('Updated chapters:', chapters);  // Debug log
      console.log('Updated filtered chapters:', filteredChapters);  // Debug log
  
      setAlert({ type: 'success', message: 'Chapter updated successfully.' });
    } catch (error) {
      console.error('Error updating chapter:', error);
      setAlert({ type: 'error', message: 'Failed to update chapter. Please try again.' });
    }
  };

  const handleChapterDelete = async (chapterId) => {
    try {
      await deleteChapter(chapterId);
      setChapters(prevChapters => prevChapters.filter(chapter => chapter.id !== chapterId));
      setFilteredChapters(prevFiltered => prevFiltered.filter(chapter => chapter.id !== chapterId));
      setAlert({ type: 'success', message: 'Chapter deleted successfully.' });
    } catch (error) {
      console.error('Error deleting chapter:', error);
      if (error.response && error.response.status === 404) {
        await fetchData();
        setAlert({ type: 'info', message: 'Chapter may have been already deleted. List refreshed.' });
      } else {
        setAlert({ type: 'error', message: 'Failed to delete chapter. Please try again.' });
      }
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!manga) return <ErrorMessage message="No manga data available." />;

  const pageCount = Math.ceil(filteredChapters.length / chaptersPerPage);
  const paginatedChapters = filteredChapters.slice(
    (currentPage - 1) * chaptersPerPage,
    currentPage * chaptersPerPage
  );

  return (
    <div className="bg-gray-900 min-h-screen text-white p-6">
      <div className="max-w-7xl mx-auto">
        <MangaCard 
          key={manga.cover_image} 
          manga={manga} 
          onMangaUpdate={handleMangaUpdate} 
        />
        <ChaptersList 
          chapters={paginatedChapters}
          allChapters={chapters}
          mangaId={mangaId} 
          onChapterUpdate={handleChapterUpdate}
          onChapterDelete={handleChapterDelete}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          columnsPerPage={columnsPerPage}
          rowsPerColumn={rowsPerColumn}
        />
        {alert && (
          <Alert
            type={alert.type}
            message={alert.message}
            onClose={() => setAlert(null)}
          />
        )}
      </div>
    </div>
  );
}

function LoadingSpinner() {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-900">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
    </div>
  );
}

function ErrorMessage({ message }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
      <AlertCircle size={64} className="text-red-500 mb-4" />
      <h1 className="text-2xl font-bold mb-2">Error</h1>
      <p className="text-center">{message}</p>
    </div>
  );
}

function ChaptersList({ chapters, allChapters, mangaId, onChapterUpdate, onChapterDelete, searchTerm, setSearchTerm, currentPage, setCurrentPage, pageCount, columnsPerPage, rowsPerColumn }) {
  const navigate = useNavigate();

  const handleAddNewChapter = () => {
    navigate(`/chapter/new/${mangaId}`);
  };

  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  const chapterColumns = chunkArray(chapters, rowsPerColumn);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-yellow-400">List Of Chapters</h2>
        <button
          onClick={handleAddNewChapter}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300 flex items-center"
        >
          <Plus size={20} className="mr-2" />
          Add New Chapter
        </button>
      </div>
      <div className="mb-4">
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder="Search chapters..."
        />
      </div>
      {chapters.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {chapterColumns.map((column, columnIndex) => (
              <div key={columnIndex} className="space-y-2 ">
                {column.map((chapter) => (
                  <ChapterSlugEditor
                    key={chapter.id}
                    chapter={chapter}
                    allChapters={allChapters}
                    onUpdate={onChapterUpdate}
                    onDelete={onChapterDelete}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-center items-center space-x-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-700 text-white rounded-md disabled:opacity-50"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="text-white">
              Page {currentPage} of {pageCount}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
              disabled={currentPage === pageCount}
              className="px-4 py-2 bg-gray-700 text-white rounded-md disabled:opacity-50"
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </>
      ) : (
        <p className="text-gray-400 text-center py-4">
          {searchTerm ? "No chapters match your search." : "No chapters available. Add a new chapter to get started!"}
        </p>
      )}
    </div>
  );
}

export default ChapterList;
import React, { useState } from 'react';
import { Upload, X, Image } from 'lucide-react';
import { uploadPages } from '../../api';

function PagesUploadModal({ mangaId, chapterId, onClose, onUploadComplete }) {
  const [pages, setPages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map((file, index) => ({
      file,
      name: file.name,
      progress: 0,
      status: 'pending',
      pageNumber: pages.length + index + 1
    }));
    setPages(prevPages => [...prevPages, ...newFiles]);
  };

  const removeFile = (index) => {
    setPages(prevPages => prevPages.filter((_, i) => i !== index));
  };

  const updatePageProgress = (index, progress) => {
    setPages(prevPages => prevPages.map((page, i) => 
      i === index ? { ...page, progress } : page
    ));
  };

  const updatePageStatus = (index, status) => {
    setPages(prevPages => prevPages.map((page, i) => 
      i === index ? { ...page, status } : page
    ));
  };

  const handlePageNumberChange = (index, newNumber) => {
    setPages(prevPages => prevPages.map((page, i) => 
      i === index ? { ...page, pageNumber: parseInt(newNumber) || 1 } : page
    ));
  };

  const handleUpload = async () => {
    if (pages.length === 0) {
      alert('Please select at least one page to upload.');
      return;
    }

    setIsUploading(true);
    let uploadedCount = 0;

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const formData = new FormData();
      formData.append('pages', page.file);
      formData.append('pageNumbers', [page.pageNumber]);

      try {
        updatePageStatus(i, 'uploading');
        updatePageProgress(i, 50);

        await uploadPages(mangaId, chapterId, formData);

        updatePageProgress(i, 100);
        updatePageStatus(i, 'uploaded');
        uploadedCount++;
      } catch (error) {
        console.error(`Error uploading page ${page.name}:`, error);
        updatePageStatus(i, 'error');
      }

      setOverallProgress(((i + 1) / pages.length) * 100);
    }

    setIsUploading(false);
    onUploadComplete();
    if (uploadedCount === pages.length) {
      onClose();
    } else {
      alert(`${uploadedCount} out of ${pages.length} pages uploaded successfully.`);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 rounded-lg p-6 max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4 text-yellow-500">Upload Chapter Pages</h2>
        
        <div className="mb-4">
          <label htmlFor="pages" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-600 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 transition-colors duration-300">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Upload className="w-8 h-8 mb-2 text-gray-400" />
              <p className="mb-2 text-sm text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop all pages here.
              </p>
              <p className="text-xs text-gray-400">PNG, JPG or any image format.</p>
            </div>
            <input
              type="file"
              id="pages"
              multiple
              onChange={handleFileChange}
              accept="image/*"
              className="hidden"
            />
          </label>
        </div>
        
        {pages.length > 0 && (
          <div className="mt-4 max-h-60 overflow-y-auto">
            {pages.map((page, index) => (
              <div key={index} className="flex items-center justify-between bg-gray-700 p-2 rounded-md mb-2">
                <div className="flex items-center flex-grow mr-2">
                  <Image className="mr-2 text-gray-300" />
                  <span className="text-sm text-white">{page.name}</span>
                </div>
                <input
                  type="number"
                  value={page.pageNumber}
                  onChange={(e) => handlePageNumberChange(index, e.target.value)}
                  className="w-16 px-2 py-1 bg-gray-600 text-white rounded mr-2"
                  min="1"
                  disabled={isUploading}
                />
                <div className="w-24 bg-gray-600 rounded-full h-2.5 mr-2">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full" 
                    style={{width: `${page.progress}%`}}
                  ></div>
                </div>
                <span className="text-xs text-gray-400 w-12">{page.status}</span>
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className="text-red-500 hover:text-red-600 ml-2"
                  disabled={isUploading}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        )}

        {isUploading && (
          <div className="mt-4">
            <div className="w-full bg-gray-600 rounded-full h-2.5">
              <div 
                className="bg-yellow-500 h-2.5 rounded-full" 
                style={{width: `${overallProgress}%`}}
              ></div>
            </div>
            <p className="text-sm text-gray-400 mt-1">Overall progress: {Math.round(overallProgress)}%</p>
          </div>
        )}

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded-md mr-2 hover:bg-gray-500 transition-colors duration-300"
            disabled={isUploading}
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            disabled={isUploading || pages.length === 0}
            className="px-4 py-2 bg-yellow-500 text-black rounded-md hover:bg-yellow-400 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isUploading ? 'Uploading...' : 'Upload Pages'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PagesUploadModal;
import React, { useState, useEffect } from 'react';
import { getMangaList } from '../api';
import PatternManagement from './utils/PatternManagement';
import Alert from './utils/Alert';
import TempStatus from './utils/TempStatus';
import { Upload } from 'lucide-react';
import ZipUploadModal from './utils/ZipUploadModal';

const ZipUploader = () => {
  const [extractedFiles, setExtractedFiles] = useState([]);
  const [alert, setAlert] = useState(null);
  const [mangaList, setMangaList] = useState([]);
  const [selectedMangaId, setSelectedMangaId] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    const fetchMangaList = async () => {
      try {
        const response = await getMangaList();
        setMangaList(response.data);
      } catch (err) {
        showAlert('Error fetching manga list: ' + err.message, 'error');
      }
    };
    fetchMangaList();
  }, []);

  const handleMangaChange = (e) => {
    setSelectedMangaId(e.target.value);
  };

  const showAlert = (message, type) => {
    setAlert({ message, type });
  };

  const handleUploadSuccess = (files) => {
    setExtractedFiles(files);
    showAlert('File uploaded successfully!', 'success');
    setShowUploadModal(false);
  };

  return (
    <div className="p-4 bg-gray-900 text-white relative">
      <h2 className="text-2xl font-bold mb-6 text-yellow-500">Upload Zip File</h2>
      <div className="mb-6 bg-gray-800 p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label htmlFor="manga" className="block text-yellow-400 font-bold mb-2">Select Manga:</label>
          <select
            id="manga"
            value={selectedMangaId}
            onChange={handleMangaChange}
            className="w-full px-3 py-2 border border-gray-700 rounded-md bg-gray-700 text-white"
          >
            <option value="">Select Manga</option>
            {mangaList.map((manga) => (
              <option key={manga.id} value={manga.id}>
                {manga.title}
              </option>
            ))}
          </select>
        </div>
        <div className="flex space-x-4">
          <button 
            onClick={() => setShowUploadModal(true)} 
            disabled={!selectedMangaId}
            className="flex-1 px-4 py-2 bg-yellow-500 text-black rounded-md font-semibold disabled:bg-gray-400 disabled:text-gray-200 flex items-center justify-center"
          >
            <Upload className="mr-2" size={20} />
            Upload ZIP File
          </button>
          <TempStatus />
        </div>
      </div>

      {extractedFiles.length > 0 && (
        <PatternManagement
          extractedFiles={extractedFiles}
          selectedMangaId={selectedMangaId}
        />
      )}
      
      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}

      {showUploadModal && (
        <ZipUploadModal
          onClose={() => setShowUploadModal(false)}
          onUploadSuccess={handleUploadSuccess}
          selectedMangaId={selectedMangaId}
        />
      )}
    </div>
  );
};

export default ZipUploader;
import React, { useState, useEffect } from 'react';
import { X, Save, XCircle, Edit3 } from 'lucide-react';

const EditModal = ({ isOpen, onClose, onSave, initialValue, title, inputType = 'text' }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    if (inputType === 'number') {
      if (e.target.value === '' || !isNaN(e.target.value)) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 modal-overlay" onClick={handleOutsideClick}>
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 transform transition-all duration-300 ease-in-out">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-yellow-500 flex items-center">
            <Edit3 size={24} className="mr-2 text-yellow-500" />
            {title}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white transition-colors duration-200">
            <X size={24} />
          </button>
        </div>
        <div className="relative">
          <input
            type={inputType}
            value={value}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded mb-4 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all duration-200"
            min={inputType === 'number' ? '1' : undefined}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => onSave(value)}
            className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-400 transition-colors duration-200 flex items-center"
          >
            <Save size={18} className="mr-2" />
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-400 transition-colors duration-200 flex items-center"
          >
            <XCircle size={18} className="mr-2" />
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
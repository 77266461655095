import React, { useState, useEffect, useCallback } from 'react';
import { Save, Edit2, Upload } from 'lucide-react';
import { updateMangaDescription } from '../../api';
import ImageUploadModal from './ImageUploadModal';
import LazyLoad from 'react-lazyload';

const MangaCard = React.memo(({ manga, onMangaUpdate }) => {
  const [editingDescription, setEditingDescription] = useState(false);
  const [newDescription, setNewDescription] = useState(manga.description);
  const [imageLoading, setImageLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  //const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  useEffect(() => {
    setNewDescription(manga.description);
  }, [manga.description]);

  const handleDescriptionUpdate = useCallback(async () => {
    try {
      await updateMangaDescription(manga.id, newDescription);
      onMangaUpdate();
      setEditingDescription(false);
    } catch (error) {
      console.error('Error updating description:', error);
    }
  }, [manga.id, newDescription, onMangaUpdate]);

  const handleImageLoad = useCallback(() => {
    setImageLoading(false);
  }, []);

  const handleUploadSuccess = useCallback(() => {
    setShowUploadModal(false);
    onMangaUpdate();
  }, [onMangaUpdate]);

  const coverImageUrl = manga.cover_image
    ? `${manga.cover_image}`
    : '/assets/placeholder.svg';

  return (
    <div className="mb-8">
      <h1 className="text-3xl font-bold mb-4 text-yellow-500">{manga.title}</h1>
      <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
        <div className="relative">
          <div className="w-full h-64 bg-gray-700 flex items-center justify-center">
            {/* {imageLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-10">
                <Loader className="animate-spin text-yellow-500" size={48} />
              </div>
            )} */}
            <LazyLoad height={256} once>
              <img 
                src={coverImageUrl}
                alt={manga.title} 
                className={`w-full h-64 object-cover ${imageLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
                onLoad={handleImageLoad}
              />
            </LazyLoad>
          </div>
          <button
            onClick={() => setShowUploadModal(true)}
            className="absolute bottom-4 right-4 bg-yellow-500 text-black px-4 py-2 rounded flex items-center hover:bg-yellow-400 transition-colors duration-300"
          >
            <Upload size={20} className="mr-2" />
            Update Cover
          </button>
        </div>
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-2 text-yellow-400">Description</h2>
          {editingDescription ? (
            <EditDescription 
              newDescription={newDescription}
              setNewDescription={setNewDescription}
              handleDescriptionUpdate={handleDescriptionUpdate}
              setEditingDescription={setEditingDescription}
            />
          ) : (
            <DisplayDescription 
              description={manga.description}
              setEditingDescription={setEditingDescription}
            />
          )}
        </div>
      </div>
      {showUploadModal && (
        <ImageUploadModal
          mangaId={manga.id}
          onClose={() => setShowUploadModal(false)}
          onUploadSuccess={handleUploadSuccess}
        />
      )}
    </div>
  );
});

const EditDescription = React.memo(({ newDescription, setNewDescription, handleDescriptionUpdate, setEditingDescription }) => {
  return (
    <div>
      <textarea
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
        className="w-full px-3 py-2 bg-gray-700 text-white rounded mb-2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
        rows="4"
      />
      <div className="flex justify-end">
        <button
          onClick={handleDescriptionUpdate}
          className="bg-yellow-500 text-black px-4 py-2 rounded mr-2 flex items-center hover:bg-yellow-400 transition-colors duration-300"
        >
          <Save size={20} className="mr-2" />
          Save
        </button>
        <button
          onClick={() => setEditingDescription(false)}
          className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 transition-colors duration-300"
        >
          Cancel
        </button>
      </div>
    </div>
  );
});

const DisplayDescription = React.memo(({ description, setEditingDescription }) => {
  return (
    <div>
      <p className="mb-2">{description}</p>
      <button
        onClick={() => setEditingDescription(true)}
        className="text-yellow-500 hover:text-yellow-400 flex items-center transition-colors duration-300"
      >
        <Edit2 size={20} className="mr-2" />
        Edit Description
      </button>
    </div>
  );
});

export default MangaCard;
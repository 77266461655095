import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, AlertCircle, Trash2, Save } from 'lucide-react';
import { updateChapterSummary } from '../../api';  // Import the API function

const ChapterSlugEditor = ({ chapter, allChapters, onUpdate, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    title: chapter.title,
    prevSlug: chapter.prev_chapter_slug || '',
    nextSlug: chapter.next_chapter_slug || '',
    summary: chapter.chapter_summary || '',
  });
  const [isSummaryEdited, setIsSummaryEdited] = useState(false);

  useEffect(() => {
    setFormData({
      title: chapter.title,
      prevSlug: chapter.prev_chapter_slug || '',
      nextSlug: chapter.next_chapter_slug || '',
      summary: chapter.chapter_summary || '',
    });
    setIsSummaryEdited(false);
  }, [chapter]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'summary') {
      setIsSummaryEdited(true);
    }
  }, []);

  const handleUpdate = useCallback(() => {
    onUpdate(chapter.id, {
      title: formData.title,
      prev_chapter_slug: formData.prevSlug,
      next_chapter_slug: formData.nextSlug,
    });
    setIsOpen(false);
  }, [chapter.id, formData, onUpdate]);

  const handleSummaryUpdate = useCallback(async () => {
    try {
      await updateChapterSummary(chapter.id, formData.summary);
      onUpdate(chapter.id, { ...chapter, chapter_summary: formData.summary });
      setIsSummaryEdited(false);
    } catch (error) {
      console.error('Error updating chapter summary:', error);
      // Handle error (e.g., show an error message to the user)
    }
  }, [chapter, formData.summary, onUpdate]);

  const handleDelete = useCallback(() => {
    onDelete(chapter.id);
    setShowDeleteModal(false);
  }, [chapter.id, onDelete]);

  const toggleOpen = useCallback(() => setIsOpen(prev => !prev), []);

  return (
    <div className="bg-gray-800 p-4 rounded-lg">
      <div className="flex items-center justify-between">
        <Link to={`/manga/${chapter.manga_id}/chapter/${chapter.id}`} className="text-lg font-semibold text-blue-400 hover:text-blue-300 transition-colors duration-300">
          {chapter.title}
        </Link>
        <button
          onClick={toggleOpen}
          className="flex items-center text-sm text-gray-400 hover:text-white hover:bg-gray-700 p-2 rounded-full transition-colors duration-300"
        >
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          <span className="ml-1">Edit Chapter</span>
        </button>
      </div>
      {isOpen && (
        <div className="mt-4 space-y-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-400">Chapter Title:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="prevSlug" className="block text-sm font-medium text-gray-400">Previous Chapter:</label>
            <select
              id="prevSlug"
              name="prevSlug"
              value={formData.prevSlug}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">None</option>
              {allChapters
                .filter(c => c.id !== chapter.id)
                .map(c => (
                  <option key={c.id} value={c.slug}>{c.title}</option>
                ))
              }
            </select>
          </div>
          <div>
            <label htmlFor="nextSlug" className="block text-sm font-medium text-gray-400">Next Chapter:</label>
            <select
              id="nextSlug"
              name="nextSlug"
              value={formData.nextSlug}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">None</option>
              {allChapters
                .filter(c => c.id !== chapter.id)
                .map(c => (
                  <option key={c.id} value={c.slug}>{c.title}</option>
                ))
              }
            </select>              
          </div>
          <div>
            <label htmlFor="summary" className="block text-sm font-medium text-gray-400">Chapter Summary:</label>
            <textarea
              id="summary"
              name="summary"
              value={formData.summary}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            />
          </div>
          {isSummaryEdited && (
            <button
              onClick={handleSummaryUpdate}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300 flex items-center"
            >
              <Save size={16} className="mr-2" />
              Update Summary
            </button>
          )}
          <div className="flex space-x-4">
            <button
              onClick={handleUpdate}
              className="flex-1 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
            >
              Update Chapter
            </button>
            <button
              onClick={() => setShowDeleteModal(true)}
              className="flex-1 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-300 flex items-center justify-center space-x-2"
            >
              <Trash2 size={16} />
              <span>Delete Chapter</span>
            </button>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setShowDeleteModal(false)}>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full" onClick={e => e.stopPropagation()}>
            <AlertCircle className="mx-auto mb-4 text-yellow-400" size={48} />
            <h3 className="text-xl font-bold mb-4 text-center text-white">Confirm Deletion</h3>
            <p className="mb-6 text-center text-gray-300">
              Are you sure you want to delete the chapter "{chapter.title}"? This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-300"
              >
                Delete
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChapterSlugEditor;
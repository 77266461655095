import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createChapter, getMangaList } from '../api';
import { BookPlus } from 'lucide-react';
import Alert from './utils/Alert';
import PagesUploadModal from './utils/PagesUploadModal';

function ChapterForm() {
  const [title, setTitle] = useState('');
  const [mangas, setMangas] = useState([]);
  const [selectedMangaId, setSelectedMangaId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createdChapterId, setCreatedChapterId] = useState(null);
  const { mangaId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMangas = async () => {
      try {
        const response = await getMangaList();
        setMangas(response.data);
        if (mangaId) {
          setSelectedMangaId(mangaId);
        } else if (response.data.length > 0) {
          setSelectedMangaId(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching manga list:', error);
        showAlert('Failed to fetch manga list. Please try again.', 'error');
      }
    };

    fetchMangas();
  }, [mangaId]);

  const showAlert = (message, type) => {
    setAlert({ message, type });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim()) {
      showAlert('Please enter a chapter title.', 'error');
      return;
    }

    setIsSubmitting(true);
    try {
      const chapterResponse = await createChapter(selectedMangaId, { title });
      setCreatedChapterId(chapterResponse.data.id);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error creating chapter:', error);
      showAlert(`Failed to create chapter: ${error.message}`, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadComplete = () => {
    showAlert('Chapter created successfully!', 'success');
    setTimeout(() => navigate(`/manga/${selectedMangaId}`), 2000);
  };

  return (
    <div className="bg-gray-900 min-h-screen p-6">
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-gray-800 p-6 rounded-lg shadow-lg text-white">
        <h2 className="text-2xl font-bold mb-6 text-yellow-500 flex items-center">
          <BookPlus className="mr-2" />
          Add New Chapter
        </h2>
        
        <div className="mb-6">
          <label htmlFor="manga" className="block text-gray-400 font-bold mb-2">Select Manga:</label>
          <select
            id="manga"
            value={selectedMangaId}
            onChange={(e) => setSelectedMangaId(e.target.value)}
            className="w-full px-3 py-2 border rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
            required
          >
            {mangas.map((manga) => (
              <option key={manga.id} value={manga.id}>
                {manga.title}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="title" className="block text-gray-400 font-bold mb-2">Chapter Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-yellow-500 text-black px-4 py-2 rounded font-semibold hover:bg-yellow-400 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
        >
          <BookPlus className="mr-2" />
          Create Chapter
        </button>
      </form>

      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}

      {isModalOpen && (
        <PagesUploadModal
          mangaId={selectedMangaId}
          chapterId={createdChapterId}
          onClose={() => setIsModalOpen(false)}
          onUploadComplete={handleUploadComplete}
        />
      )}
    </div>
  );
}

export default ChapterForm;
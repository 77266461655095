import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import {
  BookOpenText,
  BookPlus,
  Upload,
  LogOut,
  User,
  Plus,
  Menu
} from 'lucide-react';

function Sidebar() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const isActive = (path) => location.pathname === path;

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className={`bg-gray-800 text-white min-h-screen p-4 flex flex-col ${isSidebarOpen ? 'w-64' : 'w-20'}`}>
      <div className="mb-8 flex items-center justify-between">
        <h1 className={`text-2xl font-bold flex items-center whitespace-nowrap ${isSidebarOpen ? 'visible' : 'hidden'}`}>
          <BookOpenText className="mr-2" />
          Manga Storage
        </h1>
        <button
          onClick={toggleSidebar}
          className="flex items-center justify-center p-3 rounded hover:bg-gray-800 shadow-slate-300"
        >
          <Menu />
        </button>
      </div>
      <ul className="space-y-2 flex-grow">
        <SidebarLink to="/" icon={<BookOpenText />} text="Manga List" isActive={isActive('/')} isSidebarOpen={isSidebarOpen} />
        <SidebarLink to="/manga/new" icon={<BookPlus />} text="Add New Manga" isActive={isActive('/manga/new')} isSidebarOpen={isSidebarOpen} />
        <SidebarLink to="/chapter/new" icon={<Plus />} text="Add New Chapter" isActive={isActive('/chapter/new')} isSidebarOpen={isSidebarOpen} />
        <SidebarLink to="/upload-zip" icon={<Upload />} text="Upload ZIP" isActive={isActive('/upload-zip')} isSidebarOpen={isSidebarOpen} />
      </ul>
      <div className="mt-auto">
        <div className="flex items-center mb-4 justify-center md:justify-start">
          <User className="mr-2" />
          <span className={`text-sm ${isSidebarOpen ? 'visible' : 'hidden'}`}>{user.email}</span>
        </div>
        <button
          onClick={handleLogout}
          className="flex items-center p-2 rounded hover:bg-gray-700 w-full justify-center md:justify-start"
        >
          <LogOut className="mr-2" />
          <span className={`${isSidebarOpen ? 'visible' : 'hidden'}`}>Logout</span>
        </button>
      </div>
    </nav>
  );
}

function SidebarLink({ to, icon, text, isActive, isSidebarOpen }) {
  return (
    <li>
      <Link
        to={to}
        className={`flex items-center p-2 rounded hover:bg-gray-700 ${isActive ? 'bg-gray-700' : ''} ${isSidebarOpen ? 'justify-start' : 'justify-center'}`}
      >
        {React.cloneElement(icon, { className: "mr-2" })}
        <span className={`${isSidebarOpen ? 'visible' : 'hidden'}`}>{text}</span>
      </Link>
    </li>
  );
}

export default Sidebar;

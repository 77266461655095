import React, { useState, useCallback, useRef, useEffect } from 'react';
import { updatePageNumber, deletePage } from '../api';
import { X, Edit2, Trash2, ChevronLeft, ChevronRight, List, Grid, Save, XCircle } from 'lucide-react';
import Alert from './utils/Alert';
import EditModal from './utils/EditModal';

const ImageGallery = React.memo(({ images, updateChapter }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [editingImage, setEditingImage] = useState(null);
  const [alert, setAlert] = useState(null);
  const [observedImages, setObservedImages] = useState({});
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'grid'
  const [stagedChanges, setStagedChanges] = useState({});

  const getFileName = (filePath) => {
    return filePath.split('/').pop();
  };

  const handleThumbnailClick = useCallback((image) => {
    setSelectedImage(image);
  }, []);

  const handlePageNumberUpdate = useCallback((imageId, newPageNumber) => {
    setStagedChanges(prev => ({
      ...prev,
      [imageId]: { ...prev[imageId], newPageNumber: parseInt(newPageNumber) }
    }));
  }, []);

  const handleDelete = useCallback((imageId) => {
    setStagedChanges(prev => ({
      ...prev,
      [imageId]: { ...prev[imageId], deleted: true }
    }));
  }, []);

  const handleSaveChanges = async () => {
    try {
      for (const [imageId, changes] of Object.entries(stagedChanges)) {
        if (changes.deleted) {
          await deletePage(imageId);
        } else if (changes.newPageNumber !== undefined) {
          await updatePageNumber(imageId, changes.newPageNumber);
        }
      }
      await updateChapter();
      setStagedChanges({});
      setAlert({ type: 'success', message: 'Changes saved successfully' });
    } catch (error) {
      console.error('Error saving changes:', error);
      setAlert({ type: 'error', message: `Failed to save changes: ${error.message}` });
    }
  };

  const handleCancelChanges = () => {
    setStagedChanges({});
  };

  const handleClose = useCallback(() => {
    setSelectedImage(null);
  }, []);

  const handlePrevious = useCallback(() => {
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    if (currentIndex > 0) {
      setSelectedImage(images[currentIndex - 1]);
    }
  }, [images, selectedImage]);

  const handleNext = useCallback(() => {
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    if (currentIndex < images.length - 1) {
      setSelectedImage(images[currentIndex + 1]);
    }
  }, [images, selectedImage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setObservedImages((prev) => ({ ...prev, [entry.target.dataset.id]: true }));
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '200px' }
    );

    return () => observer.disconnect();
  }, []);

  const ImageItem = React.memo(({ image, viewMode }) => {
    const imgRef = useRef(null);
    const fileName = getFileName(image.file_path);
    const isDeleted = stagedChanges[image.id]?.deleted;
    const isEdited = stagedChanges[image.id]?.newPageNumber !== undefined;

    useEffect(() => {
      if (imgRef.current && !observedImages[image.id]) {
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              setObservedImages((prev) => ({ ...prev, [image.id]: true }));
              observer.unobserve(entries[0].target);
            }
          },
          { rootMargin: '200px' }
        );
        observer.observe(imgRef.current);
        return () => observer.disconnect();
      }
    }, [image.id]);

    const itemClassName = `
      ${viewMode === 'list' ? 'flex items-center justify-between bg-gray-800 p-2 rounded-lg mb-2' : 'relative group'}
      ${isDeleted ? 'border-red-500 border-2' : ''}
      ${isEdited ? 'border-blue-500 border-2' : ''}
    `;

    if (viewMode === 'list') {
      return (
        <div className={itemClassName}>
          <div className="flex items-center">
            <img
              ref={imgRef}
              src={observedImages[image.id] ? `${image.file_path}` : ''}
              alt={`Page ${image.page_number}`}
              data-id={image.id}
              className="w-16 h-16 object-cover rounded-lg mr-4"
              onClick={() => handleThumbnailClick(image)}
              loading="lazy"
            />
            <div>
              <p className="font-semibold">{fileName}</p>
              <p className="text-sm text-gray-400">
                Page {stagedChanges[image.id]?.newPageNumber || image.page_number}
              </p>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={() => setEditingImage(image)}
              className="bg-yellow-500 text-black p-1 rounded-full mr-2"
              aria-label="Edit page number"
            >
              <Edit2 size={16} />
            </button>
            <button
              onClick={() => handleDelete(image.id)}
              className="bg-red-500 text-white p-1 rounded-full"
              aria-label="Delete page"
            >
              <Trash2 size={16} />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className={itemClassName}>
        <img
          ref={imgRef}
          src={observedImages[image.id] ? `${image.file_path}` : ''}
          alt={`Page ${image.page_number}`}
          data-id={image.id}
          className="w-full h-48 object-cover cursor-pointer rounded-lg transition-transform duration-300 transform group-hover:scale-105"
          onClick={() => handleThumbnailClick(image)}
          loading="lazy"
        />
        <div className="absolute top-0 left-0 right-0 bg-black bg-opacity-75 text-white p-2 rounded-t-lg text-sm truncate">
          {fileName}
        </div>
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 text-white p-2 rounded-b-lg">
          Page {stagedChanges[image.id]?.newPageNumber || image.page_number}
        </div>
        <div className="absolute top-0 right-0 p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button
            onClick={() => setEditingImage(image)}
            className="bg-yellow-500 text-black p-1 rounded-full mr-2"
            aria-label="Edit page number"
          >
            <Edit2 size={16} />
          </button>
          <button
            onClick={() => handleDelete(image.id)}
            className="bg-red-500 text-white p-1 rounded-full"
            aria-label="Delete page"
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
    );
  });

  return (
    <div className="bg-gray-900 text-white">
      <div className="flex justify-between mb-4">
        <div>
          <button
            onClick={() => setViewMode('list')}
            className={`mr-2 p-2 rounded ${viewMode === 'list' ? 'bg-blue-500' : 'bg-gray-700'}`}
          >
            <List size={20} />
          </button>
          <button
            onClick={() => setViewMode('grid')}
            className={`p-2 rounded ${viewMode === 'grid' ? 'bg-blue-500' : 'bg-gray-700'}`}
          >
            <Grid size={20} />
          </button>
        </div>
        {Object.keys(stagedChanges).length > 0 && (
          <div>
            <button
              onClick={handleSaveChanges}
              className="bg-green-500 text-white px-4 py-2 rounded mr-2"
            >
              <Save size={20} className="inline mr-2" />
              Save Changes
            </button>
            <button
              onClick={handleCancelChanges}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              <XCircle size={20} className="inline mr-2" />
              Cancel Changes
            </button>
          </div>
        )}
      </div>
      <div className={viewMode === 'grid' ? "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4" : ""}>
        {images.map((image) => (
          <ImageItem key={image.id} image={image} viewMode={viewMode} />
        ))}
      </div>
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
          <button
            className="absolute top-4 right-4 text-white text-3xl"
            onClick={handleClose}
            aria-label="Close fullscreen view"
          >
            <X size={32} />
          </button>
          <button
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
            onClick={handlePrevious}
            aria-label="Previous image"
          >
            <ChevronLeft size={48} />
          </button>
          <button
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white"
            onClick={handleNext}
            aria-label="Next image"
          >
            <ChevronRight size={48} />
          </button>
          <img
            src={`${selectedImage.file_path}`}
            alt={`Page ${selectedImage.page_number}`}
            className="max-h-[90vh] max-w-[90vw] object-contain"
          />
        </div>
      )}
      <EditModal
        isOpen={editingImage !== null}
        onClose={() => setEditingImage(null)}
        onSave={(newPageNumber) => {
          handlePageNumberUpdate(editingImage.id, newPageNumber);
          setEditingImage(null);
        }}
        initialValue={editingImage?.page_number.toString() || ''}
        title="Edit Page Number"
        inputType="number"
      />
      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
    </div>
  );
});

export default ImageGallery;
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-900">
      {/* Image Section */}
      <div className="hidden lg:flex lg:w-1/2 bg-cover bg-center"><img src='/assets/login.svg' alt='login'/></div>
      
      <div className="w-full lg:w-1/2 flex items-center justify-center px-6 py-12">
        <div className="w-full max-w-md">
          <h2 className="text-3xl font-bold text-center text-yellow-500 mb-6">Login Form</h2>
          
          <div className="flex mb-6">
            <button className="flex-1 bg-yellow-500 text-black font-bold py-2 px-4 rounded-l">
              Login
            </button>
            <Link to="/register" className="flex-1 bg-gray-700 text-white font-bold py-2 px-4 rounded-r hover:bg-gray-600 transition-colors duration-300 text-center">
              Signup
            </Link>
          </div>
          
          {error && (
            <div className="mb-4 p-3 bg-red-500 text-white rounded flex items-center">
              <AlertCircle size={20} className="mr-2" />
              <span>{error}</span>
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email Address"
                className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <input
                type="password"
                placeholder="Password"
                className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="text-right mb-6">
              <Link to="/forgot-password" className="text-sm text-blue-400 hover:text-blue-300">
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full bg-yellow-500 text-black font-bold py-2 px-4 rounded hover:bg-yellow-400 transition-colors duration-300"
            >
              Login
            </button>
          </form>
          
          <div className="mt-6 text-center">
            <p className="text-gray-400">
              Not a member? <Link to="/register" className="text-blue-400 hover:text-blue-300">Signup now</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
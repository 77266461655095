import axios from 'axios';
import { auth } from '../firebase';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;


const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  if (config.method !== 'get') {
    config.headers.Authorization = `Bearer ${API_TOKEN}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Manga API endpoints
export const getMangaList = () => api.get('/manga');
export const createManga = (mangaData) => api.post('/manga', mangaData, {headers: { 'Content-Type': 'multipart/form-data' }});
export const renameManga = (mangaId, newName) => api.put(`/manga/${mangaId}/rename`, { newName });
export const deleteManga = (mangaId) => api.delete(`/manga/${mangaId}`);
export const getMangaDetails = (mangaId) => api.get(`/manga/${mangaId}`);
export const updateMangaDescription = (mangaId, newDescription) => api.put(`/manga/${mangaId}/description`, { newDescription });
export const updateMangaCoverImage = (mangaId, formData) => api.put(`/manga/${mangaId}/cover-image`, formData, { headers: { 'Content-Type': 'multipart/form-data' }
});

// Chapter API endpoints
export const getChapterList = (mangaId) => api.get(`/manga/${mangaId}/chapters`);
export const createChapter = (mangaId, chapterData) => api.post(`/manga/${mangaId}/chapters`, chapterData);
export const getChapterDetails = (mangaId, chapterId) => api.get(`/manga/${mangaId}/chapters/${chapterId}`);
export const deleteChapter = (chapterId) => api.delete(`/chapters/${chapterId}`);
export const renameChapter = (chapterId, newName) => api.put(`/chapters/${chapterId}/rename`, { newName });
export const updateChapterSummary = (chapterId, newSummary) => api.put(`/chapters/${chapterId}/summary`, { newSummary });
export const updateChapter = (chapterId, chapterData) => api.put(`/chapters/${chapterId}`, chapterData);

// Chapter Page API endpoints
export const getPages = (mangaId, chapterId) => api.get(`/manga/${mangaId}/chapters/${chapterId}/pages`);
export const deletePage = (pageId) => api.delete(`/pages/${pageId}`);
export const uploadPages = (mangaId, chapterId, formData) => api.post(`/manga/${mangaId}/chapters/${chapterId}/pages`, formData, {headers: { 'Content-Type': 'multipart/form-data' }});
export const updatePageNumber = (pageId, newPageNumber) => api.put(`/pages/${pageId}/update-number`, { newPageNumber });


// New endpoints for uploading zip files
export const uploadZipFile = (mangaId, formData) => api.post(`/manga/${mangaId}/upload-zip`, formData, {headers: { 'Content-Type': 'multipart/form-data' }});
export const categorizeFiles = (mangaId, data) => api.post(`/manga/${mangaId}/categorize`, data);
export const confirmUpload = (mangaId, categorizedFiles) => api.post(`/manga/${mangaId}/confirm-upload`, { categorizedFiles });

export const createBulkChapters = (mangaId, chaptersData) => api.post(`/manga/${mangaId}/bulk-chapters`, chaptersData);
export const bulkUploadPages = (mangaId, chapterId, pagesData) => api.post(`/manga/${mangaId}/chapters/${chapterId}/bulk-pages`, pagesData);

// Utils
export const cleanupTempFolder = () => api.post('/cleanup-temp');
export const getTempFileCount = () => api.get('/temp-file-count');

// User API endpoints
export const updateUserProfile = (userData) => api.put('/user/profile', userData);
export const getCurrentUser = () => {const user = auth.currentUser;return user ? { uid: user.uid, email: user.email, displayName: user.displayName } : null;};

//Chunk size for uploading files
export const initializeZipUpload = (mangaId, fileInfo) => api.post(`/manga/${mangaId}/initialize-upload`, fileInfo);
export const uploadZipChunk = (mangaId, sessionId, chunkIndex, chunkData) => api.post(`/manga/${mangaId}/upload-chunk/${sessionId}/${chunkIndex}`, chunkData, {headers: { 'Content-Type': 'multipart/form-data' }});
export const finalizeZipUpload = (mangaId, sessionId) => api.post(`/manga/${mangaId}/finalize-upload/${sessionId}`);


export const logout = () => auth.signOut();
export default api;
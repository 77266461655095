import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { Link } from 'react-router-dom';
import { AlertCircle, CheckCircle } from 'lucide-react';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Check your inbox.');
      setError('');
    } catch (error) {
      setError(error.message);
      setMessage('');
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-900">
      {/* Image Section */}
      <div className="hidden lg:flex lg:w-1/2 bg-cover bg-center"><img src='/assets/forgot.svg' alt='forgot-password'/></div>
      
      {/* Forgot Password Form Section */}
      <div className="w-full lg:w-1/2 flex items-center justify-center px-6 py-12">
        <div className="w-full max-w-md">
          <h2 className="text-3xl font-bold text-center text-yellow-500 mb-6">Forgot Password</h2>
          
          {message && (
            <div className="mb-4 p-3 bg-green-500 text-white rounded flex items-center">
              <CheckCircle size={20} className="mr-2" />
              <span>{message}</span>
            </div>
          )}
          {error && (
            <div className="mb-4 p-3 bg-red-500 text-white rounded flex items-center">
              <AlertCircle size={20} className="mr-2" />
              <span>{error}</span>
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <input
                type="email"
                placeholder="Email Address"
                className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-yellow-500 text-black font-bold py-2 px-4 rounded hover:bg-yellow-400 transition-colors duration-300"
            >
              Reset Password
            </button>
          </form>
          
          <div className="mt-6 text-center">
            <p className="text-gray-400">
              Remember your password? <Link to="/login" className="text-blue-400 hover:text-blue-300">Login here</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'lucide-react';

const MangaCardSmall = React.memo(({ manga, onEdit, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  const handleDeleteClick = useCallback(() => {
    setIsDeleting(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (deleteConfirmation === manga.title) {
      onDelete(manga.id);
    } else {
      alert('Please enter the correct manga title to confirm deletion.');
    }
    setIsDeleting(false);
    setDeleteConfirmation('');
  }, [deleteConfirmation, manga.id, manga.title, onDelete]);

  const handleDeleteCancel = useCallback(() => {
    setIsDeleting(false);
    setDeleteConfirmation('');
  }, []);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden flex flex-col h-full">
      <div className="relative pt-[56.25%]"> {/* 16:9 Aspect Ratio */}
        <img 
          src={manga.cover_image ? `${manga.cover_image}` : '/assets/placeholder.svg'} 
          alt={manga.title} 
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h2 className="text-xl font-semibold text-yellow-400 mb-2 line-clamp-2">{manga.title}</h2>
        <Link to={`/manga/${manga.id}`} className="text-blue-400 hover:text-blue-300 mt-auto">
          View Chapters
        </Link>
        <div className="mt-4 flex justify-end">
          <button 
            onClick={() => onEdit(manga)}
            className="mr-2 text-yellow-500 hover:text-yellow-400"
            aria-label="Edit manga"
          >
            <Edit2 size={20} />
          </button>
          <button 
            onClick={handleDeleteClick}
            className="text-red-500 hover:text-red-400"
            aria-label="Delete manga"
          >
            <Trash2 size={20} />
          </button>
        </div>
      </div>
      {isDeleting && (
        <div className="p-4 bg-red-900">
          <p className="text-white mb-2">Enter the manga title to confirm deletion:</p>
          <input 
            type="text" 
            value={deleteConfirmation} 
            onChange={(e) => setDeleteConfirmation(e.target.value)}
            className="w-full px-2 py-1 mb-2 bg-gray-700 text-white rounded"
          />
          <div className="flex justify-end">
            <button 
              onClick={handleDeleteConfirm}
              className="bg-red-500 text-white px-2 py-1 rounded mr-2"
            >
              Confirm Delete
            </button>
            <button 
              onClick={handleDeleteCancel}
              className="bg-gray-500 text-white px-2 py-1 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

MangaCardSmall.displayName = 'MangaCardSmall';

export default MangaCardSmall;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getChapterDetails, getPages, updateChapterSummary } from '../api';
import ImageGallery from './ImageGallery';
import Alert from './utils/Alert';
import PagesUploadModal from './utils/PagesUploadModal';
import { Upload, Save, Edit2, BookOpen, ArrowLeft, ArrowRight } from 'lucide-react';

const ChapterDetail = () => {
  const [chapter, setChapter] = useState(null);
  const [pages, setPages] = useState([]);
  const [alert, setAlert] = useState(null);
  const [editingSummary, setEditingSummary] = useState(false);
  const [newSummary, setNewSummary] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const { mangaId, chapterId } = useParams();

  const showAlert = useCallback((message, type) => {
    setAlert({ message, type });
  }, []);

  const fetchChapterDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const [chapterResponse, pagesResponse] = await Promise.all([
        getChapterDetails(mangaId, chapterId),
        getPages(mangaId, chapterId)
      ]);
      setChapter(chapterResponse.data);
      setPages(pagesResponse.data);
      setNewSummary(chapterResponse.data.chapter_summary || '');
    } catch (error) {
      console.error('Error fetching chapter details:', error);
      showAlert('Error fetching chapter details.', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [mangaId, chapterId, showAlert]);

  useEffect(() => {
    fetchChapterDetails();
  }, [fetchChapterDetails]);

  const handleImageUpdate = useCallback(() => {
    fetchChapterDetails();
  }, [fetchChapterDetails]);

  const handleSummaryUpdate = useCallback(async () => {
    try {
      await updateChapterSummary(chapterId, newSummary);
      setChapter(prev => ({ ...prev, chapter_summary: newSummary }));
      setEditingSummary(false);
      showAlert('Chapter summary updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating chapter summary:', error);
      showAlert(`Error updating chapter summary: ${error.response?.data?.error || error.message}`, 'error');
    }
  }, [chapterId, newSummary, showAlert]);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleUploadComplete = () => {
    fetchChapterDetails();
    showAlert('Pages uploaded successfully!', 'success');
    setIsUploadModalOpen(false);
  };

  const memoizedChapterSummary = useMemo(() => (
    <div className="mb-8 bg-gray-800 p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl">
      <h3 className="text-xl font-semibold mb-4 text-yellow-400 flex items-center">
        <BookOpen className="mr-2" />
        Chapter Summary
      </h3>
      {editingSummary ? (
        <div className="space-y-4">
          <textarea
            value={newSummary}
            onChange={(e) => setNewSummary(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded mb-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-colors duration-300"
            rows="4"
            placeholder="Enter chapter summary..."
          />
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleSummaryUpdate}
              className="bg-yellow-500 text-black px-4 py-2 rounded flex items-center hover:bg-yellow-400 transition-colors duration-300"
            >
              <Save size={20} className="mr-2" />
              Save
            </button>
            <button
              onClick={() => setEditingSummary(false)}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 transition-colors duration-300"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className="mb-4 text-gray-300">{chapter?.chapter_summary || 'No summary available.'}</p>
          <button
            onClick={() => setEditingSummary(true)}
            className="text-yellow-500 hover:text-yellow-400 flex items-center transition-colors duration-300"
          >
            <Edit2 size={20} className="mr-2" />
            Edit Summary
          </button>
        </div>
      )}
    </div>
  ), [chapter, editingSummary, newSummary, handleSummaryUpdate]);

  const memoizedImageGallery = useMemo(() => (
    <ImageGallery
      images={pages}
      updateChapter={handleImageUpdate}
    />
  ), [pages, handleImageUpdate]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  if (!chapter) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <h2 className="text-3xl font-bold mb-4">Chapter not found</h2>
        <p className="text-gray-400 mb-8">The requested chapter could not be found.</p>
        <button
          onClick={() => window.history.back()}
          className="bg-yellow-500 text-black px-6 py-2 rounded font-semibold hover:bg-yellow-400 transition-colors duration-300 flex items-center"
        >
          <ArrowLeft size={20} className="mr-2" />
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 text-white min-h-screen p-6 relative">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold text-yellow-500 flex items-center">
            Chapter: {chapter.title}
          </h2>
          <div className="flex space-x-2">
            {chapter.prev_chapter_slug && (
              <button className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors duration-300 flex items-center">
                <ArrowLeft size={20} className="mr-2" />
                Previous Chapter
              </button>
            )}
            {chapter.next_chapter_slug && (
              <button className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors duration-300 flex items-center">
                Next Chapter
                <ArrowRight size={20} className="ml-2" />
              </button>
            )}
          </div>
        </div>
        
        {memoizedChapterSummary}
        
        <div className="mb-8 bg-gray-800 p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl">
          <h3 className="text-xl font-semibold mb-4 text-yellow-400 flex items-center">
            Upload New Pages
          </h3>
          <button
            onClick={handleOpenUploadModal}
            className="bg-yellow-500 text-black px-6 py-2 rounded font-semibold hover:bg-yellow-400 transition-colors duration-300 flex items-center"
          >
            <Upload size={20} className="mr-2" />
            Upload Pages
          </button>
        </div>
        
        {memoizedImageGallery}
        
        {alert && (
          <Alert
            type={alert.type}
            message={alert.message}
            onClose={() => setAlert(null)}
          />
        )}
        
        {isUploadModalOpen && (
          <PagesUploadModal
            mangaId={mangaId}
            chapterId={chapterId}
            onClose={handleCloseUploadModal}
            onUploadComplete={handleUploadComplete}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ChapterDetail);
import React, { useState, useEffect } from 'react';
import { Trash2, AlertCircle } from 'lucide-react';
import { cleanupTempFolder, getTempFileCount } from '../../api';
import Alert from './Alert';

const TempStatus = () => {
  const [fileCount, setFileCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    fetchFileCount();
  }, []);

  const fetchFileCount = async () => {
    setIsLoading(true);
    try {
      console.log('Fetching file count...');
      const response = await getTempFileCount();
      console.log('File count response:', response);
      setFileCount(response.data.count);
    } catch (error) {
      console.error('Error fetching file count:', error);
      console.error('Error details:', error.response);
      setAlert({ type: 'error', message: `Failed to fetch temporary file count: ${error.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCleanup = async () => {
    try {
      await cleanupTempFolder();
      setAlert({ type: 'success', message: 'Temporary folder cleaned up successfully!' });
      fetchFileCount();
    } catch (error) {
      setAlert({ type: 'error', message: 'Error cleaning up temporary folder: ' + error.message });
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <button
        onClick={() => setShowConfirmModal(true)}
        className="px-4 py-2 bg-red-500 text-white rounded-md font-semibold disabled:bg-gray-400 disabled:text-gray-200 flex items-center justify-center"
        disabled={isLoading || fileCount === 0}
      >
        <Trash2 className="mr-2" size={20} />
        {isLoading ? 'Loading...' : `Clear Temp (${fileCount} files)`}
      </button>

      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
            <AlertCircle className="mx-auto mb-4 text-yellow-400" size={48} />
            <h3 className="text-xl font-bold mb-4 text-center text-white">Confirm Cleanup</h3>
            <p className="mb-6 text-center text-gray-300">
              Are you sure you want to clean up {fileCount} files in the temporary folder?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleCleanup}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Yes, Clean Up
              </button>
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
    </>
  );
};

export default TempStatus;
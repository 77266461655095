import React, { useState, useCallback } from 'react';
import { X, Upload, Loader, Image as ImageIcon } from 'lucide-react';
import { updateMangaCoverImage } from '../../api';
import { useDropzone } from 'react-dropzone';

const ImageUploadModal = ({ mangaId, onClose, onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false
  });

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('coverImage', file);

    try {
      await updateMangaCoverImage(mangaId, formData);
      onUploadSuccess();
    } catch (error) {
      console.error('Error updating cover image:', error);
      // You might want to show an error message to the user here
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Upload Cover Image</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white transition-colors duration-200">
            <X size={24} />
          </button>
        </div>
        <div 
          {...getRootProps()} 
          className={`mb-6 border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors duration-200
            ${isDragActive ? 'border-blue-500 bg-blue-100 bg-opacity-10' : 'border-gray-600 hover:border-gray-500'}`}
        >
          <input {...getInputProps()} />
          {preview ? (
            <img src={preview} alt="Preview" className="mx-auto max-h-48 rounded" />
          ) : (
            <div className="text-gray-400">
              <ImageIcon size={48} className="mx-auto mb-4" />
              <p>Drag and drop an image here, or click to select</p>
            </div>
          )}
        </div>
        {file && (
          <p className="text-white mb-4 text-center">Selected: {file.name}</p>
        )}
        <button
          onClick={handleUpload}
          disabled={!file || uploading}
          className="w-full bg-yellow-500 text-black py-3 px-4 rounded-lg font-semibold flex items-center justify-center hover:bg-yellow-400 transition-colors duration-200 disabled:bg-gray-500 disabled:cursor-not-allowed"
        >
          {uploading ? (
            <>
              <Loader className="animate-spin mr-2" size={20} />
              Uploading...
            </>
          ) : (
            <>
              <Upload size={20} className="mr-2" />
              Upload
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default ImageUploadModal;
import React, { useState, useCallback } from 'react';
import { Upload, X, CheckCircle, AlertCircle } from 'lucide-react';
import { initializeZipUpload, uploadZipChunk, finalizeZipUpload } from '../../api';

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks

const ZipUploadModal = ({ onClose, onUploadSuccess, selectedMangaId }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [error, setError] = useState(null);

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setUploadProgress(0);
    setUploadStatus('idle');
    setError(null);
  };

  const handleUpload = useCallback(async () => {
    if (!file) return;

    setUploadStatus('uploading');
    setError(null);
    
    try {
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      const { data: { sessionId } } = await initializeZipUpload(selectedMangaId, {
        fileName: file.name,
        fileSize: file.size,
        totalChunks,
      });

      for (let i = 0; i < totalChunks; i++) {
        const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        const formData = new FormData();
        formData.append('chunk', chunk, `${file.name}.part${i}`);
        
        await uploadZipChunk(selectedMangaId, sessionId, i, formData);
        // const { data } = await uploadZipChunk(selectedMangaId, sessionId, i, formData);
        setUploadProgress((i + 1) / totalChunks * 100);
      }

      setUploadStatus('processing');
      const { data: extractedFiles } = await finalizeZipUpload(selectedMangaId, sessionId);
      
      setUploadStatus('success');
      onUploadSuccess(extractedFiles.files);
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus('error');
      setError(error.response?.data?.error || 'An unknown error occurred');
    }
  }, [file, selectedMangaId, onUploadSuccess]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-yellow-500">Upload ZIP File</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>

        <input
          type="file"
          onChange={handleFileSelect}
          accept=".zip,.cbz"
          className="mb-4 w-full px-3 py-2 border border-gray-700 rounded-md bg-gray-700 text-white"
        />

        {file && (
          <div className="mb-4">
            <p className="text-sm text-gray-300">File: {file.name}</p>
            <p className="text-sm text-gray-300">Size: {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
          </div>
        )}

        <button
          onClick={handleUpload}
          disabled={!file || uploadStatus === 'uploading' || uploadStatus === 'processing' || uploadStatus === 'success'}
          className="w-full bg-yellow-500 text-black px-4 py-2 rounded-md font-semibold disabled:bg-gray-400 disabled:text-gray-200 flex items-center justify-center mb-4"
        >
          {uploadStatus === 'uploading' ? (
            <>
              <Upload className="animate-spin mr-2" size={20} />
              Uploading...
            </>
          ) : uploadStatus === 'processing' ? (
            <>
              <Upload className="animate-spin mr-2" size={20} />
              Processing...
            </>
          ) : uploadStatus === 'success' ? (
            <>
              <CheckCircle className="mr-2" size={20} />
              Upload Complete
            </>
          ) : (
            <>
              <Upload className="mr-2" size={20} />
              Start Upload
            </>
          )}
        </button>

        {(uploadStatus === 'uploading' || uploadStatus === 'processing') && (
          <div className="mb-4">
            <div className="w-full bg-gray-700 rounded-full h-2.5">
              <div
                className="bg-yellow-500 h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-sm text-gray-300 mt-2">Progress: {uploadProgress.toFixed(2)}%</p>
          </div>
        )}

        {error && (
          <div className="text-red-500 flex items-center">
            <AlertCircle className="mr-2" size={20} />
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default ZipUploadModal;